import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import StreamingAvatar from "@heygen/streaming-avatar";

import { HeyGenService } from "../../../core/services/heygen.service";
import { MessageService } from "../../../core/services/message.service";
import { AlertService } from "../../../core/services/alert.service";
import { ConfigService } from "../../../core/services/config.service";
import { AvatarConfigRequest } from "../../../core/models/avatarConfig";
import { EventService } from "../../../core/services/event.service";
import { MicrophoneService } from "../../../core/services/microphone.service";

@Component({
    selector: 'app-hey-gen-avatar',
    templateUrl: './hey-gen-avatar.component.html',
    styleUrl: './hey-gen-avatar.component.scss'
})
export class HeyGenAvatarComponent implements AfterViewInit, OnInit, OnDestroy {
    private videoElement!: HTMLVideoElement;
    private canvas!: HTMLCanvasElement;
    private avatar: StreamingAvatar | null = null;
    private sessionData: any = null;
    private avatarText: string = '';
    private avatarConfig: AvatarConfigRequest = { name: '', voiceId: '' };
    private talkStarted: boolean = false;
    private isShowAvatarEnable: boolean = false;
    private avatarTalkOptions: string[] = [];

    avatarTextSubscription!: Subscription;
    avatarConfigRequestSubscription!: Subscription;
    talkSubscription!: Subscription;
    startMobileStreamSubscription!: Subscription;
    showAvatarEnableSubscription!: Subscription;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private heygen: HeyGenService,
        private message: MessageService,
        private alert: AlertService,
        private config: ConfigService,
        private event: EventService,
        private microphone: MicrophoneService) { }

    ngOnInit(): void {
        this.avatarConfigRequestSubscription = this.config.getAvatarConfigRequest().subscribe(async config => {
            // Take avatar config from new language
            this.avatarConfig = config;

            // On language change start again heygen avatar initialization on PC
            if (!this.isMobile() && this.videoElement && this.isShowAvatarEnable) {
                await this.initializeAvatarSession();
            }

            // If we change language on mobile device and talk is started, start heygen avatar initialization
            if (this.isMobile() && this.talkStarted && this.videoElement) {
                await this.initializeAvatarSession();
            }
        });

        // On OK click start heygen avatar initialization on PC
        this.showAvatarEnableSubscription = this.event.getShowAvatarEnable().subscribe(async isShown => {
            this.isShowAvatarEnable = isShown;

            if (isShown && this.videoElement) {
                await this.initializeAvatarSession();
            }
        });

        // Avatar read all received AI messages
        this.avatarTextSubscription = this.message.getCurrentAvatarText().subscribe(async options => {
            this.avatarText = options.text;

            // If mobile device and talk is not started, do not read messages
            if (this.isMobile() && !this.talkStarted) {
                return;
            }

            /*if (this.talkStarted) {
                await this.heygen.handleSpeak(options.text);
            }*/

            if (this.avatarTalkOptions.includes(options.type)) {
                await this.heygen.handleSpeak(options.text);
            }
        });

        // On mobile call start heygen avatar initialization
        this.talkSubscription = this.event.getStartedTalk().subscribe(async isTalkStarted => {
            this.talkStarted = isTalkStarted;

            // Start heygen avatar initialization on mobile device
            if (this.isMobile() && this.talkStarted) {
                await this.initializeAvatarSession();
            }
        });

        this.avatarTalkOptions = this.config.getAvatarTalkOptions();
    }

    async ngAfterViewInit() {
        this.videoElement = document.getElementById("avatarVideo") as HTMLVideoElement;
        this.canvas = document.getElementById("avatarCanvas") as HTMLCanvasElement;

        this.heygen.setAvatarVideo(this.videoElement);
        this.heygen.setAvatarCanvas(this.canvas);
    }

    async initializeAvatarSession() {
        try {
            this.alert.showLoading();

            if (this.avatar && this.videoElement) {
                this.avatar = null;
                await this.terminateAvatarSession();
            }

            const { avatar, sessionData } = await this.heygen.initializeAvatarSession(this.avatarConfig);
            this.avatar = avatar;
            this.sessionData = sessionData;

            this.heygen.onStreamReady(this.handleStreamReady.bind(this));
            this.heygen.onSpeakCompleted(this.handleSpeakCompleted.bind(this));
            this.heygen.onStreamDisconnected(this.handleStreamDisconnected.bind(this));

            if (this.isMobile()) {
                await this.microphone.onMicrophoneClick(this.changeDetector, true);
            }
        } catch (error) {
            this.alert.showError("Error", "Error initializing avatar session");
        }
    }

    // Handle when avatar stream is ready
    async handleStreamReady(event: any) {
        if (event.detail && this.videoElement) {
            this.videoElement.srcObject = event.detail;

            this.videoElement.onloadedmetadata = () => {
                this.videoElement.play().then(() => {
                    this.alert.close(); // Close loading indicator when video is ready to play
                    this.heygen.startChromaKeying(); // Start chrome keying
                }).catch(console.error);
            };
        } else {
            this.alert.showError("Error", "Stream is not available");
        }
    }

    async handleSpeakCompleted(event: any) {
        this.heygen.isAvatarSpeaking.next(false);
        console.log("Avatar finish with speaking", event);

        // Start microphone again after avatar stops talking
        if (this.talkStarted) {
             this.microphone.setMicrophoneTalkStatus(false);
             await this.microphone.onMicrophoneClick(this.changeDetector, true);
        }
    }

    handleStreamDisconnected() {
        console.log("Stream disconnected");
        if (this.videoElement) {
            this.videoElement.srcObject = null;
        }
    }

    private isMobile(): boolean {
        return window.innerWidth <= 992;
    }

    async terminateAvatarSession() {
        await this.heygen.terminateAvatarSession(this.videoElement);
    }

    async ngOnDestroy() {
        if (this.avatarTextSubscription){ this.avatarTextSubscription.unsubscribe(); }
        if (this.avatarConfigRequestSubscription){ this.avatarConfigRequestSubscription.unsubscribe(); }
        if (this.talkSubscription){ this.talkSubscription.unsubscribe(); }
        if (this.startMobileStreamSubscription){ this.startMobileStreamSubscription.unsubscribe(); }
        if (this.showAvatarEnableSubscription){ this.showAvatarEnableSubscription.unsubscribe(); }
        await this.terminateAvatarSession();
    }
}
