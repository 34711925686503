import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { VisibilityService } from "../../../core/services/visibility.service";
import { EventService } from "../../../core/services/event.service";
import { LanguageService } from "../../../core/services/language.service";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent implements OnInit, OnDestroy {
    companyLogo: string = "assets/icons/logo.svg"

    loadingTexts: string[][] = [
        ["Working on it!", "Finding the Most Accurate Answer for You...", "Hang tight, we're almost there!"],
        ["Looking into that right now...", "Collecting the Most Relevant Info...", "Gathering details to provide clarity..."],
        ["Getting Things Ready for You...", "Gathering the Details You Need...", "Sorting out the Best Solution for You..."],
        ["Let me sort this out for you...", "Finding exactly what you’re asking for...", "Reviewing everything for accuracy..."],
        ["Thinking this through...", "Retrieving the right details...", "Finding exactly what you need..."],
        ["Let me think that through...", "Gathering the necessary information...", "Evaluating the options carefully..."]
    ];

    currentPhase: string = "";
    currentIndex: number = 0;
    currentTextIndex: number = 0;
    intervalId: any;

    isLoadingShown: boolean = false;

    loadingBarSubscription!: Subscription;
    translateSubscription!: Subscription;

    constructor(private event: EventService,
                private cdr: ChangeDetectorRef,
                private visibility: VisibilityService,
                private language: LanguageService) { }

    ngOnInit(): void {
        this.loadingBarSubscription = this.visibility.getVisibility('avatar-loader').subscribe(async loading => {
            this.isLoadingShown = loading;

            if (this.isLoadingShown) {
                this.startLoadingProcess();
            } else {
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                }
            }
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.loadingTexts = translate.typography.loadingTexts;
        });
    }

    startLoadingProcess() {
        this.currentIndex = Math.floor(Math.random() * this.loadingTexts.length);
        this.currentTextIndex = 0;
        this.currentPhase = this.loadingTexts[this.currentIndex][this.currentTextIndex];

        this.intervalId = setInterval(() => {
            this.currentTextIndex++;

            if (this.currentTextIndex < this.loadingTexts[this.currentIndex].length) {
                this.currentPhase = this.loadingTexts[this.currentIndex][this.currentTextIndex];
            } else {
                this.currentPhase = this.loadingTexts[this.currentIndex][this.currentTextIndex - 1];
                clearInterval(this.intervalId);
            }
        }, 2000);
    }



    get phaseLetters(): string[] {
        return this.currentPhase.split('').map(char => char === ' ' ? '\u00A0' : char);
    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        if (this.loadingBarSubscription) { this.loadingBarSubscription.unsubscribe(); }
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
    }
}
